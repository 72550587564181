exports.components = {
  "component---src-articles-2023-05-06-here-we-go-mdx": () => import("./../../../src/articles/2023-05-06-here-we-go.mdx" /* webpackChunkName: "component---src-articles-2023-05-06-here-we-go-mdx" */),
  "component---src-articles-2023-05-07-github-actions-getting-started-mdx": () => import("./../../../src/articles/2023-05-07-github-actions-getting-started.mdx" /* webpackChunkName: "component---src-articles-2023-05-07-github-actions-getting-started-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-de-mdx": () => import("./../../../src/pages/about.de.mdx" /* webpackChunkName: "component---src-pages-about-de-mdx" */),
  "component---src-pages-about-en-mdx": () => import("./../../../src/pages/about.en.mdx" /* webpackChunkName: "component---src-pages-about-en-mdx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-freelancing-de-tsx": () => import("./../../../src/pages/freelancing.de.tsx" /* webpackChunkName: "component---src-pages-freelancing-de-tsx" */),
  "component---src-pages-freelancing-en-tsx": () => import("./../../../src/pages/freelancing.en.tsx" /* webpackChunkName: "component---src-pages-freelancing-en-tsx" */),
  "component---src-pages-imprint-mdx": () => import("./../../../src/pages/imprint.mdx" /* webpackChunkName: "component---src-pages-imprint-mdx" */),
  "component---src-pages-index-de-mdx": () => import("./../../../src/pages/index.de.mdx" /* webpackChunkName: "component---src-pages-index-de-mdx" */),
  "component---src-pages-index-en-mdx": () => import("./../../../src/pages/index.en.mdx" /* webpackChunkName: "component---src-pages-index-en-mdx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */)
}

